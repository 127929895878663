@use 'variables';

html, body {
  /**
   To avoid the default styles from being overwritten by the global stylesheet from the ITSupportChatWidget client.
   @see https://tiny.amazon.com/eek7znym/codeamazpackITSAblobca93src
   @see https://developer.mozilla.org/en-US/docs/Web/CSS/all
   */
  all: initial !important;
}

body {
  margin: 0;
}

#app {
  .its-day1-steps-view {
    // Exact measurements as intentional here.
    width: 892px;

    margin-left: 15%;
    margin-right: 15%;

    @media screen and (max-width: 800px) {
      margin-left: 25px;
      margin-right: 25px;
    }

    .active-step, .disabled-step {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    }

    .step-image {
      height: 100%;
      width: 100%;
      min-width: 0;
    }

    .incomplete-step-btn {
      font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
      color: variables.$its-white;
      background-color: variables.$its-grey;
      border-radius: 20px;
      border-style: none;
      height: 30px;
      width: 30px;
    }

    .active-step-btn {
      font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
      color: variables.$its-white;
      background-color: variables.$its-purple;
      border-radius: 20px;
      border-style: none;
      height: 30px;
      width: 30px;
    }

    .wizard-next-btn-row {
      padding-bottom: 20px;
    }

    .disabled-step {
      pointer-events: none;
      opacity: 0.4;
    }

    .stepList-text {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .stepLists {
      list-style-position: outside;
      padding-left: 15px;
      padding-right: 15px;
    }

    // Make record steps appear clickable.
    .clickable-record-step:hover {
      cursor: pointer;
      button {
        border-color: variables.$primary70;
      }
    }

    .wizard-link:hover {
      cursor: pointer;
    }

    .video-overlay-box {
      margin: 0 1rem 0;
      position: absolute;
      z-index: 10;
    }
  }

  .reg-security-key-ol {
    padding-left: 1rem;
  }

  .reg-security-key-ol-ol {
    padding-left: 1.25rem;
  }
}
